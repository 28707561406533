<template>
  <div class="container">
    <div class="box">
      <div class="top_title"> {{newsInfo.title}} </div>
      <div class="top_time"> {{newsInfo.time}} </div>
      <div class="top_text"> {{newsInfo.text}} </div>
      <img class="top_img" :src="newsInfo.img">
      <div class="top_hint"> {{newsInfo.hint}} </div>
      <div class="content">
        <div class="content_box" v-for="content in newsInfo.contentList" :key="content">
          <div class="content_title"> {{content.title ? content.title : ''}} </div>
          <div class="content_text"> {{content.text}} </div>
          <div class="content_text"> {{content.text_2}} </div>
          <div class="content_text"> {{content.text_3}} </div>
          <div class="content_text"> {{content.text_4}} </div>
          <div class="content_text"> {{content.text_5}} </div>
          <div class="content_text"> {{content.text_6}} </div>
          <div class="content_text"> {{content.text_7}} </div>
        </div>
      </div>
    </div>
    <!--<news></news>-->
  </div>
</template>

<script>
  import MyHeader from '@/components/MyHeader.vue'
  export default {
    name: "newsDetail",
    watch:{
    },
    data(){
      return{
        newsInfo: {}
      }
    },
    methods:{
      getQuery(){
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.newsInfo = ''
        this.newsInfo = JSON.parse(localStorage.getItem('item'))
      },
    },
    created(){

    },
    mounted(){
      this.getQuery()
    },
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    align-items center
    .box{
      display flex
      flex-flow column
      width 1088px
      font-family Alibaba PuHuiTi 2.0
      font-weight normal
      padding 50px 0
      .top_title{
        font-size 56px
        line-height 67px
        color #343A40
      }
      .top_time{
        font-size 16px
        line-height 24px
        color #6C757D
        padding 12px 0 42px 0
        border-bottom 1px solid #E2E6EA
      }
      .top_text{
        font-size 20px
        line-height 30px
        color #6C757D
        margin 40px 0
        text-indent 2em
      }
      .top_img{
        width 1088px
        border-radius 16px
      }
      .top_hint{
        text-align center
        margin 16px 0
        color #6C757D
      }
      .content{
        display flex
        flex-flow column
        border-bottom 1px solid #E2E6EA
        padding-bottom 40px
        .content_box{
          .content_title{
            font-size 28px
            line-height 56px
            color #343A40
          }
          .content_text{
            font-size 20px
            line-height 30px
            color #6C757D
            margin 40px 0
            text-indent 2em
          }
          .content_intro{
            margin-top 22px
            font-size 16px
            line-height 24px
            text-align center
            color #B0B6BA
          }
        }

      }
    }
  }
</style>
